import { Progress } from '@chakra-ui/react';
import { collection, doc, getDoc, onSnapshot, query, updateDoc } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionOnce, useDocumentOnce } from 'react-firebase-hooks/firestore';
import { Section } from '../constants/types';
import { auth, db } from '../firebaseApp';

type sectionsContextReturn = {
  sections: string[];
  selected: string;
  loading: boolean;
  setSelected: (section: string) => void;
};

const defaultContext: sectionsContextReturn = {
  sections: [],
  selected: '',
  loading: true,
  setSelected: () => {},
};

const SectionsContext = createContext(defaultContext);

export function SectionsProvider({ children }: PropsWithChildren<{}>) {
  const [user, userLoading, userError] = useAuthState(auth);
  const [selected, setStateSelected] = useState('');
  const [loading, setLoading] = useState(true);
  const [sections, setSections] = useState<Section[]>([]);

  useEffect(() => {
    setLoading(true);
    if (user) {
      getDoc(doc(db, 'users', user.uid)).then((doc) => {
        if (doc.exists()) {
          doc.data().access && setSections(doc.data().access);
          doc.data().selectedSection && setStateSelected(doc.data().selectedSection);
        }
        setLoading(false);
      });
    }
  }, [user, userLoading, userError]);

  function setSelected(section: string) {
    if (user) {
      updateDoc(doc(db, 'users', user.uid), {
        selectedSection: section,
      }).then((data) => {
        setStateSelected(section);
      });
    }
  }

  return <SectionsContext.Provider value={{ sections, loading, selected, setSelected }}>{children}</SectionsContext.Provider>;
}

export function useSections() {
  const context = useContext(SectionsContext);
  if (context === undefined) {
    throw new Error('useSections must be used within a SectionsProvider');
  }
  return context;
}

export default SectionsProvider;
