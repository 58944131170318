import React from 'react';
import { Box, Heading, HStack, Progress, SimpleGrid, VStack } from '@chakra-ui/react';
import { collection, limit, query, where } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection, useCollectionOnce } from 'react-firebase-hooks/firestore';
import { useNavigate } from 'react-router-dom';
import UserTableRow from '../components/UserTableRow';
import { User } from '../constants/types';
import { auth, db } from '../firebaseApp';

export default function Counter() {
  const [user, userLoading, userError] = useAuthState(auth);
  const navigate = useNavigate();
  const usersCollection = collection(db, 'users');
  const usersRef = query(usersCollection, where('isChecked', '==', true));
  const [values, loading, error] = useCollection(usersRef);
  const usersTodayRef = query(usersCollection, where('isChecked', '==', true), where('isInternal', '==', false));
  const [valuesToday, loadingToday, errorToday] = useCollection(usersTodayRef);

  if (userLoading || loadingToday || loading) {
    return <Progress isIndeterminate />;
  }

  if (userError || errorToday || !user) navigate('/sign-in');
  return (
    <Box background="karneBlue.500" textColor="white" h="100vh" w="100vw" justifyContent="center" display="flex" alignItems="center">
      <VStack>
        <VStack>
          <Heading fontSize="10vh">Totalt antal Karnevalister registrerade</Heading>
          <Heading fontSize="40vh">{values && values.docs.length}</Heading>
        </VStack>
        <VStack>
          <Heading fontSize="5vh">Antal Karnevalister registrerade idag</Heading>
          <Heading fontSize="20vh">{valuesToday && valuesToday.docs.length}</Heading>
        </VStack>
      </VStack>
    </Box>
  );
}
