import { TradeOffer, User } from '../constants/types';
import { db } from '../firebaseApp';
import { doc, deleteDoc, updateDoc, setDoc } from 'firebase/firestore';
import {
  useToast,
  Button,
  Tr,
  Td,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Tooltip,
  Box,
  VStack,
} from '@chakra-ui/react';
import UserTradeItem from './UserTradeItem';
import { useState } from 'react';
import UserOverviewModal from './UserOverviewModal';

interface Props {
  offer: TradeOffer;
  id: string;
}

export default function UserTableRow({ offer, id }: Props) {
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
  const toast = useToast();

  function onRemove() {
    deleteDoc(doc(db, 'offers', id)).then((data) => {
      toast({
        title: 'Erbjudane bortaget!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    });
  }

  return (
    <>
      <Tr>
        <Tooltip label="Klicka för att se mer">
          <Td onClick={onOpen1} cursor="pointer">
            {offer.offer.firstName + ' ' + offer.offer.lastName}
          </Td>
        </Tooltip>
        <Tooltip label="Klicka för att se mer">
          <Td onClick={onOpen2} cursor="pointer">
            {offer.user.firstName + ' ' + offer.user.lastName}
          </Td>
        </Tooltip>
        <Td maxW="4rem">
          <Button colorScheme="karneRed" onClick={onRemove}>
            Ta bort
          </Button>
        </Td>
      </Tr>
      <UserOverviewModal isOpen={isOpen1} onClose={onClose1} user={offer.offer} />

      <UserOverviewModal isOpen={isOpen2} onClose={onClose2} user={offer.user} />
    </>
  );
}
