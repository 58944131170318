import { Progress, Table, Thead, Tr, Th, Tbody, Box, SimpleGrid, Heading } from '@chakra-ui/react';
import { collection, limit, query, where } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection, useCollectionOnce } from 'react-firebase-hooks/firestore';
import { useNavigate } from 'react-router-dom';
import UserOfferTableRow from '../components/UserOfferTableRow';
import UserTradeTableRow from '../components/UserTradeTableRow';
import { TradeOffer, User } from '../constants/types';
import { auth, db } from '../firebaseApp';
import { useSections } from '../providers/SectionsProvider';

export default function Offers() {
  const [user, userLoading, userError] = useAuthState(auth);
  const navigate = useNavigate();
  const { selected } = useSections();
  const offersCollection = collection(db, 'offers');
  const createdOffersRef = query(offersCollection, where('offerSection', '==', selected));
  const recievedOffersRef = query(offersCollection, where('section', '==', selected));
  const [valuesCreated, loadingCreated] = useCollection(createdOffersRef);
  const [valuesRecieved, loadingRecieved] = useCollection(recievedOffersRef);

  if (userLoading || loadingCreated || loadingRecieved) {
    return <Progress isIndeterminate />;
  }

  if (userError || !user) navigate('/sign-in');
  return (
    <SimpleGrid columns={2} spacing="1rem">
      <Box>
        <Heading textAlign="center" mb="1rem" size="lg">
          Mina skapade erbjudanden
        </Heading>
        <Box overflowX="scroll">
          <Table variant="striped" colorScheme="karneRed">
            <Thead position="sticky" top="0">
              <Tr background="karneBlue.500">
                <Th textColor="white">Min karnevalist</Th>
                <Th textColor="white">Erbjudande</Th>
                <Th textColor="white" maxW="5rem">
                  Ta bort
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {valuesCreated && valuesCreated.docs.map((offer) => <UserOfferTableRow key={offer.id} id={offer.id} offer={offer.data() as TradeOffer} />)}
            </Tbody>
          </Table>
        </Box>
      </Box>
      <Box>
        <Heading textAlign="center" mb="1rem" size="lg">
          Mina erbjudanden
        </Heading>
        <Box overflowX="scroll">
          <Table variant="striped" colorScheme="karneRed">
            <Thead position="sticky" top="0">
              <Tr background="karneBlue.500">
                <Th textColor="white">Erbjudande</Th>
                <Th textColor="white">Min karnevalist</Th>
                <Th textColor="white" maxW="6rem">
                  Acceptera
                </Th>
                <Th textColor="white" maxW="4rem">
                  Neka
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {valuesRecieved && valuesRecieved.docs.map((offer) => <UserTradeTableRow key={offer.id} id={offer.id} offer={offer.data() as TradeOffer} />)}
            </Tbody>
          </Table>
        </Box>
      </Box>
    </SimpleGrid>
  );
}
