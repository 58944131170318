import { Box, Button } from '@chakra-ui/react';
import { User } from '../constants/types';

interface Props {
  section: String;
  active: boolean;
  onSelect: () => void;
}

export default function SectionItem({ section, active, onSelect }: Props) {
  return (
    <Button onClick={onSelect} isFullWidth isActive={active} colorScheme={active ? 'karneGreen' : 'karneRed'}>
      {section}
    </Button>
  );
}
