import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import Users from './pages/Users';
import SignIn from './pages/SignIn';
import Layout from './Layout';
import { theme } from './Theme/theme';
import Counter from './pages/Counter';
import Marketplace from './pages/Marketplace';
import Trades from './pages/Trades';
import Offers from './pages/Offers';
import Settings from './pages/Settings';
import History from './pages/History';
import SectionsProvider from './providers/SectionsProvider';
import SettingsProvider from './providers/SettingsProvider';
import Available from './pages/Available';
import NewUsers from './pages/NewUsers';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ChakraProvider theme={theme}>
        <SectionsProvider>
          <SettingsProvider>
            <Layout>
              <Routes>
                <Route path="/" element={<Users />} />
                <Route path="/new-users" element={<NewUsers />} />
                <Route path="/trades" element={<Trades />} />
                <Route path="/marketplace" element={<Marketplace />} />
                <Route path="/offers" element={<Offers />} />
                <Route path="/available" element={<Available />} />
                <Route path="/counter" element={<Counter />} />
                <Route path="/sign-in" element={<SignIn />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/trade-history" element={<History />} />
              </Routes>
            </Layout>
          </SettingsProvider>
        </SectionsProvider>
      </ChakraProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
