import { TradeOffer, User } from '../constants/types';
import { auth, db } from '../firebaseApp';
import { doc, deleteDoc, updateDoc, setDoc, addDoc, collection } from 'firebase/firestore';
import {
  useToast,
  Button,
  Tr,
  Td,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Tooltip,
  Box,
  VStack,
} from '@chakra-ui/react';
import UserTradeItem from './UserTradeItem';
import { useState } from 'react';
import UserOverviewModal from './UserOverviewModal';
import { FirebaseError } from 'firebase/app';
import { useAuthState } from 'react-firebase-hooks/auth';

interface Props {
  offer: TradeOffer;
  id: string;
}

export default function UserTableRow({ offer, id }: Props) {
  const [user, userLoading, userError] = useAuthState(auth);
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();
  const { isOpen: isOpen2, onOpen: onOpen2, onClose: onClose2 } = useDisclosure();
  const toast = useToast();

  async function onAccept() {
    try {
      await updateDoc(doc(db, 'users', offer.offer.docId), {
        section: offer.section,
      });
      await updateDoc(doc(db, 'users', id), {
        section: offer.offerSection,
      });
      await addDoc(collection(db, 'tradeHistory'), {
        offer: {
          firstName: offer.offer.firstName,
          lastName: offer.offer.lastName,
          id: offer.offer.docId,
          oldSection: offer.offerSection,
          newSection: offer.section,
        },
        user: {
          firstName: offer.user.firstName,
          lastName: offer.user.lastName,
          id: id,
          oldSection: offer.section,
          newSection: offer.offerSection,
        },
        timestamp: new Date(),
        traderId: user?.uid,
      });
      await deleteDoc(doc(db, 'offers', id));
      await deleteDoc(doc(db, 'marketplace', id));
      await deleteDoc(doc(db, 'marketplace', offer.offer.docId));
      await deleteDoc(doc(db, 'trades', offer.offer.docId));
      await deleteDoc(doc(db, 'trades', id));

      toast({
        title: 'Bytet genomfört!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    } catch (e: any) {
      console.log(e);
      toast({
        title: 'Något gick snett',
        description: 'Skyll på Wilhelm Åkerman',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
  }

  function onDeny() {
    deleteDoc(doc(db, 'offers', id)).then((data) => {
      toast({
        title: 'Erbjudanet nekades!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    });
  }

  return (
    <>
      <Tr>
        <Tooltip label="Klicka för att se mer">
          <Td onClick={onOpen1} cursor="pointer">
            {offer.offer.firstName + ' ' + offer.offer.lastName}
          </Td>
        </Tooltip>
        <Tooltip label="Klicka för att se mer">
          <Td onClick={onOpen2} cursor="pointer">
            {offer.user.firstName + ' ' + offer.user.lastName}
          </Td>
        </Tooltip>
        <Td maxW="6rem">
          <Button colorScheme="karneGreen" onClick={onAccept}>
            Acceptera
          </Button>
        </Td>
        <Td maxW="4rem">
          <Button colorScheme="karneRed" onClick={onDeny}>
            Neka
          </Button>
        </Td>
      </Tr>
      <UserOverviewModal isOpen={isOpen1} onClose={onClose1} user={offer.offer} />

      <UserOverviewModal isOpen={isOpen2} onClose={onClose2} user={offer.user} />
    </>
  );
}
