export interface User {
  firstName: string;
  lastName: string;
  email: string;
  ssn: string;
  mandate: boolean;
  phone: string;
  image?: string;
  docId: string;
  isChecked: boolean;
  isRegistered: boolean;
  sectionOrder: SectionObject[];
  interests: string[];
  section: string;
  oldSection: string;
  role: string;
  bff: string;
  gender: string;
  other: string;
}

export interface TradeOffer {
  section: Section;
  offerSection: Section;
  user: User;
  offer: User;
}

export interface SectionObject {
  name: Section;
  order: number;
}

export interface Trade {
  offer: {
    id: string;
    firstName: string;
    lastName: string;
    newSection: string;
    oldSection: string;
  };
  user: {
    id: string;
    firstName: string;
    lastName: string;
    newSection: string;
    oldSection: string;
  };
  timestamp: any;
  traderId: string;
}

export type Section =
  | 'Foodtrucks: Mystery Mobiles'
  | 'Revyn'
  | 'Tälten'
  | 'Tombolan'
  | 'Krog Peppar Peppar'
  | 'Krogen Under Jorden'
  | 'Ekonomi'
  | 'Synderiet'
  | 'Säkerhet'
  | 'Förmedleriet'
  | 'Övrigt'
  | 'Filmen'
  | 'Krog Terminal 5'
  | 'Krog Sista Oasen'
  | 'Musiken'
  | 'Barnevalen'
  | 'Smånöjen'
  | 'Tåget'
  | 'Vieriet'
  | 'Showen'
  | 'Blädderiet'
  | 'Spexet'
  | 'Snaxeriet'
  | 'Entrén'
  | 'Lundagård'
  | 'Cirkusen'
  | 'Konkursen'
  | 'Kabarén'
  | 'Radion'
  | 'Dansen'
  | 'Fabriken'
  | 'BIT-KOM';

export const sections = [
  'Foodtrucks: Mystery Mobiles',
  'Revyn',
  'Tälten',
  'Tombolan',
  'Krog Peppar Peppar',
  'Krogen Under Jorden',
  'Ekonomi',
  'Synderiet',
  'Säkerhet',
  'Förmedleriet',
  'Övrigt',
  'Filmen',
  'Krog Terminal 5',
  'Krog Sista Oasen',
  'Musiken',
  'Barnevalen',
  'Smånöjen',
  'Tåget',
  'Vieriet',
  'Showen',
  'Blädderiet',
  'Spexet',
  'Snaxeriet',
  'Entrén',
  'Lundagård',
  'Cirkusen',
  'Konkursen',
  'Kabarén',
  'Radion',
  'Dansen',
  'Fabriken',
  'BIT-KOM',
];
