import { Progress, Table, Thead, Tr, Th, Tbody, Box } from '@chakra-ui/react';
import { collection, limit, query, where } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection, useCollectionOnce } from 'react-firebase-hooks/firestore';
import { useNavigate } from 'react-router-dom';
import UserTableRow from '../components/UserTableRow';
import { User } from '../constants/types';
import { auth, db } from '../firebaseApp';
import { useSections } from '../providers/SectionsProvider';
import { useSettings } from '../providers/SettingsProvider';

export default function Trades() {
  const [user, userLoading, userError] = useAuthState(auth);
  const navigate = useNavigate();
  const { allowTrading } = useSettings();
  const { selected } = useSections();
  const usersCollection = collection(db, 'trades');
  const usersRef = query(usersCollection, where('section', '==', selected));
  const [values, loading, error] = useCollection(usersRef);

  if (userLoading || loading) {
    return <Progress isIndeterminate />;
  }

  if (userError || !user) navigate('/sign-in');
  return (
    <Box overflowX="scroll">
      <Table variant="striped" colorScheme="karneRed">
        <Thead position="sticky" top="0">
          <Tr background="karneBlue.500">
            <Th textColor="white">Nummer ({values && values.docs.length})</Th>
            <Th textColor="white">Namn</Th>
            <Th textColor="white">Kön</Th>
            <Th textColor="white">Email</Th>
            <Th textColor="white">Telefonnummer</Th>
            <Th textColor="white">Ansvarig</Th>
            <Th textColor="white">Karnekompis (KK)</Th>
            <Th textColor="white">Intressen</Th>
            <Th textColor="white">Önskade Sektioner</Th>
            <Th textColor="white">Övrigt</Th>
            <Th textColor="white">Byt</Th>
            {allowTrading && <Th textColor="white">Gör Tillgänglig</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {values &&
            values.docs.map((user, index) => (
              <UserTableRow key={user.id} showSection={false} id={user.id} index={index} action="trade" user={user.data() as User} />
            ))}
        </Tbody>
      </Table>
    </Box>
  );
}
