import { Box, Button } from '@chakra-ui/react';
import { User } from '../constants/types';

interface Props {
  user: User;
  active: boolean;
  onSelect: () => void;
}

export default function UserTradeItem({ user, active, onSelect }: Props) {
  return (
    <Button onClick={onSelect} isFullWidth isActive={active} colorScheme={active ? 'karneGreen' : 'karneRed'}>
      {user.firstName + ' ' + user.lastName}
    </Button>
  );
}
