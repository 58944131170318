import { Box, Checkbox, Container, FormControl, FormLabel, Heading, Progress, SimpleGrid, Switch, VStack } from '@chakra-ui/react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseApp';
import { useSections } from '../providers/SectionsProvider';
import { useSettings } from '../providers/SettingsProvider';

export default function Settings() {
  const [user, userLoading, userError] = useAuthState(auth);
  const navigate = useNavigate();
  const { sections } = useSections();
  const { allowTrading, allowDownloadEmailList, setAllowDownloadEmailList, setAllowTrading } = useSettings();
  if (userLoading) {
    return <Progress isIndeterminate />;
  }
  if (userError || !user || !sections.includes('Admin')) navigate('/sign-in');
  return (
    <Container maxW="container.md">
      <SimpleGrid columns={1}>
        <Heading size="lg">Inställningar</Heading>
        <VStack py="1rem" spacing="1rem">
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="allowTrading" mb="0">
              Tillåt Byten
            </FormLabel>
            <Switch size="lg" id="allowTrading" isChecked={allowTrading} value="allowTrading" onChange={() => setAllowTrading(!allowTrading)} />
          </FormControl>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="allowEmail" mb="0">
              Tillåtet nerladdning av email listor
            </FormLabel>
            <Switch
              size="lg"
              id="allowEmail"
              isChecked={allowDownloadEmailList}
              value="allowEmail"
              onChange={() => setAllowDownloadEmailList(!allowDownloadEmailList)}
            />
          </FormControl>
        </VStack>
      </SimpleGrid>
    </Container>
  );
}
