import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react';

const colors = {
  karneBlue: {
    50: '#a2b1d6',
    100: '#8fa1ce',
    200: '#7c91c6',
    300: '#6981bd',
    400: '#5772b5',
    500: '#4462AD',
    600: '#3d589c',
    700: '#364e8a',
    800: '#304579',
    900: '#293b68',
  },
  karneGreen: {
    50: '#c6e8e3',
    100: '#bbe3dd',
    200: '#afdfd8',
    300: '#a4dad2',
    400: '#98d6cd',
    500: '#8DD1C7',
    600: '#7fbcb3',
    700: '#71a79f',
    800: '#63928b',
    900: '#557d77',
  },
  karneYellow: {
    50: '#f8f5ac',
    100: '#f7f39b',
    200: '#f5f18b',
    300: '#f4ef7a',
    400: '#f2ed6a',
    500: '#F1EB59',
    600: '#d9d450',
    700: '#c1bc47',
    800: '#a9a53e',
    900: '#918d35',
  },
  karneRed: {
    50: '#f8bbcd',
    100: '#f7adc2',
    200: '#f5a0b8',
    300: '#f492ae',
    400: '#f285a4',
    500: '#F1779A',
    600: '#d96b8b',
    700: '#c15f7b',
    800: '#91475c',
    900: '#91475c',
  },
  karneOrange: {
    50: '#fee480',
    100: '#fede66',
    200: '#fed94d',
    300: '#fdd333',
    400: '#fdce1a',
    500: '#FDC800',
    600: '#e4b400',
    700: '#caa000',
    800: '#b18c00',
    900: '#987800',
  },
};

export const theme = extendTheme({ colors }, withDefaultColorScheme({ colorScheme: 'karneGreen' }));
