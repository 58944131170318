import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyCsbqo__zzu-4_TZMK6DSFvqcTH6-HDhrQ',
  authDomain: 'appdev-c3c07.firebaseapp.com',
  projectId: 'appdev-c3c07',
  storageBucket: 'appdev-c3c07.appspot.com',
  messagingSenderId: '1033592693395',
  appId: '1:1033592693395:web:014f361fb03393eec12366',
  measurementId: 'G-J5BYGWRCRW',
}

const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp);
const auth = getAuth(firebaseApp);

export { db, auth }
