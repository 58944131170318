import { Td, Tr } from '@chakra-ui/react';
import { Trade } from '../constants/types';

interface Props {
  trade: Trade;
  index: number;
}

export default function TradeTableRow({ trade, index }: Props) {
  return (
    <Tr>
      <Td>{index + 1}</Td>
      <Td>{trade.offer.firstName + ' ' + trade.offer.lastName}</Td>
      <Td>{trade.offer.newSection}</Td>
      <Td>{trade.user.firstName + ' ' + trade.user.lastName}</Td>
      <Td>{trade.user.newSection}</Td>
      <Td>{trade.timestamp.toDate().toString()}</Td>
    </Tr>
  );
}
