import {
  Container,
  VStack,
  FormControl,
  FormLabel,
  useToast,
  FormErrorMessage,
  Input,
  Button,
  Heading,
  Progress,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react';
import { FirebaseError } from 'firebase/app';
import { sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseApp';

export default function SignIn() {
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, userLoading, userError] = useAuthState(auth);
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  function onSubmit(event: any) {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password).catch((e: FirebaseError) => {
      toast({
        title: 'Något gick snett!',
        description: e.message,
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    });
  }

  function forgottPassword(event: any) {
    event.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        onClose();
        toast({
          title: 'Lösenord nollstält',
          description: 'Kolla i din email efter vidare instruktioner',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((e: FirebaseError) => {
        console.log(e.message);
        toast({
          title: 'Något gick snett!',
          description: 'Hittade inte en användaren med den emailen!',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      });
  }

  function onChangeEmail(event: any) {
    setEmail(event.target.value);
  }

  function onChangePassword(event: any) {
    setPassword(event.target.value);
  }

  if (userLoading) {
    return <Progress isIndeterminate />;
  }

  if (!userError && user) navigate('/');

  return (
    <Container>
      <Heading variant="h2" my="2rem">
        Sign in
      </Heading>
      <form onSubmit={onSubmit}>
        <VStack spacing="4">
          <FormControl>
            <FormLabel htmlFor="email">Email address</FormLabel>
            <Input value={email} onChange={onChangeEmail} id="email" type="email" />
          </FormControl>
          <FormControl>
            <FormLabel htmlFor="password">Password</FormLabel>
            <Input value={password} onChange={onChangePassword} id="password" type="password" />
          </FormControl>
          <Button w="100%" type="submit">
            Sign In
          </Button>

          <Button w="100%" colorScheme="karneOrange" onClick={onOpen}>
            Glömt lösenord?
          </Button>
        </VStack>
      </form>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={forgottPassword}>
            <ModalHeader>Glömt lösenord?</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel htmlFor="email">Email address</FormLabel>
                <Input value={email} onChange={onChangeEmail} id="email" type="email" />
              </FormControl>
            </ModalBody>

            <ModalFooter>
              <Button type="submit">Nollställ lösenord</Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </Container>
  );
}
