import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Table,
  Thead,
  Th,
  Tr,
  Heading,
  Text,
  ListItem,
  UnorderedList,
  OrderedList,
  Box,
} from '@chakra-ui/react';
import { User } from '../constants/types';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  user: User;
}

export default function UserOverviewModal({ isOpen, onClose, user }: Props) {
  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{user.firstName + ' ' + user.lastName + ' - ' + user.ssn}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box pb="0.5rem">
            <Heading size="md">Kön</Heading>
            <Text>{user.gender}</Text>
          </Box>
          <Box py="0.5rem">
            <Heading size="md">Ansvarig</Heading>
            <Text>{user.role === 'Ansvarig' ? 'Ja' : 'Nej'}</Text>
          </Box>
          <Box py="0.5rem">
            <Heading size="md">Karnekompis (KK)</Heading>
            <Text>{user.bff ? user.bff : 'Har ingen KK'}</Text>
          </Box>
          <Box py="0.5rem">
            <Heading size="md">Intressen</Heading>
            <UnorderedList>{user.interests && user.interests.map((s, i) => <ListItem key={i}>{s}</ListItem>)}</UnorderedList>
          </Box>
          <Box py="0.5rem">
            <Heading size="md">Önskade Sektioner</Heading>
            <OrderedList>
              {user?.sectionOrder
                ? user.sectionOrder.sort((s1, s2) => s1.order - s2.order).map((s) => <ListItem key={s.order}>{s.name}</ListItem>)
                : 'Inga val gjorda'}
            </OrderedList>
          </Box>
          <Box py="0.5rem">
            <Heading size="md">Övrig</Heading>
            <Text>{user.other ? user.other : 'Har inte skrivit något i övrigt'}</Text>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
