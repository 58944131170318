import {
  Progress,
  Table,
  Th,
  Thead,
  useClipboard,
  Textarea,
  Text,
  Tr,
  Tbody,
  Box,
  HStack,
  Heading,
  Button,
  useDisclosure,
  Modal,
  ListItem,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  UnorderedList,
  ModalFooter,
} from '@chakra-ui/react';
import { addDoc, collection, doc, limit, query, setDoc, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection, useCollectionOnce } from 'react-firebase-hooks/firestore';
import { useNavigate } from 'react-router-dom';
import UserTableRow from '../components/UserTableRow';
import { User } from '../constants/types';
import { auth, db } from '../firebaseApp';
import { useSections } from '../providers/SectionsProvider';
import { useSettings } from '../providers/SettingsProvider';

export default function Users() {
  const [user, userLoading, userError] = useAuthState(auth);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { selected, sections } = useSections();
  const { allowDownloadEmailList, allowTrading } = useSettings();
  const navigate = useNavigate();
  const usersCollection = collection(db, 'users');
  const usersRef = query(usersCollection, where('isChecked', '==', true), where('section', '==', selected));
  const [values, loading, error] = useCollection(usersRef);
  const [emails, setEmails] = useState('');
  const { hasCopied, onCopy } = useClipboard(emails);

  useEffect(() => {
    values && setEmails(values.docs.map((doc) => doc.data().email).join(' '));
  }, [values]);

  async function copyEmails() {
    onCopy();
    await addDoc(collection(db, 'emailCopies'), {
      section: selected,
      timestamp: new Date(),
      userId: user?.uid,
    });
  }

  if (userLoading || loading) {
    return <Progress isIndeterminate />;
  }

  if (userError || !user) navigate('/sign-in');

  return (
    <Box>
      <HStack m="1rem" justifyContent="space-between">
        <Heading size="lg">{selected}s karnevalister</Heading>
        {allowDownloadEmailList && <Button onClick={onOpen}>Email lista</Button>}
      </HStack>
      <Box overflowX="scroll">
        <Table variant="striped" colorScheme="karneRed" zIndex="1">
          <Thead position="sticky" top="0">
            <Tr background="karneBlue.500">
              <Th textColor="white">Nummer ({values && values.docs.length})</Th>
              <Th textColor="white">Namn</Th>
              <Th textColor="white">Kön</Th>
              <Th textColor="white">Email</Th>
              <Th textColor="white">Telefonnummer</Th>
              <Th textColor="white">Ansvarig</Th>
              <Th textColor="white">Karnekompis (KK)</Th>
              <Th textColor="white">Intressen</Th>
              <Th textColor="white">Önskade Sektioner</Th>
              <Th textColor="white">Övrigt</Th>
              <Th textColor="white">Byt</Th>
              {allowTrading && <Th textColor="white">Gör Tillgänglig</Th>}
              {sections.includes('Admin') && <Th textColor="white">Flytta</Th>}
            </Tr>
          </Thead>
          <Tbody>
            {values &&
              values.docs.map((user, index) => (
                <UserTableRow key={user.id} action="sell" showSection={false} id={user.id} index={index} user={user.data() as User} />
              ))}
          </Tbody>
        </Table>
      </Box>
      {allowDownloadEmailList && (
        <Modal size="xl" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Email lista</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb="1rem">
              <Box h="60vh" overflowY="scroll">
                {emails.split(' ').map((email) => (
                  <Text key={email}>{email}</Text>
                ))}
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button onClick={copyEmails} colorScheme={hasCopied ? 'karneGreen' : 'karneBlue'}>
                {hasCopied ? 'Kopierade listan' : 'Kopiera listan'}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}
