import {
  Progress,
  Table,
  Th,
  Thead,
  useClipboard,
  Textarea,
  Text,
  Tr,
  Tbody,
  Box,
  HStack,
  Heading,
  Button,
  useDisclosure,
  Modal,
  ListItem,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  OrderedList,
  UnorderedList,
  ModalFooter,
} from '@chakra-ui/react';
import { collection, limit, query, where } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollection, useCollectionOnce } from 'react-firebase-hooks/firestore';
import { useNavigate } from 'react-router-dom';
import TradeTableRow from '../components/TradeTableRow';
import UserTableRow from '../components/UserTableRow';
import { Trade, User } from '../constants/types';
import { auth, db } from '../firebaseApp';
import { useSections } from '../providers/SectionsProvider';
import { useSettings } from '../providers/SettingsProvider';

export default function Users() {
  const [user, userLoading, userError] = useAuthState(auth);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { selected, sections } = useSections();
  const { allowDownloadEmailList, allowTrading } = useSettings();
  const navigate = useNavigate();
  const historyCollection = collection(db, 'tradeHistory');
  const historyRef = query(historyCollection);
  const [values, loading, error] = useCollection(historyRef);

  if (userLoading || loading) {
    return <Progress isIndeterminate />;
  }

  if (userError || !user || !sections.includes('Admin')) navigate('/sign-in');
  return (
    <Box>
      <Heading m="1rem" size="lg">
        Bytes historik
      </Heading>
      <Box overflowX="scroll">
        <Table variant="striped" colorScheme="karneRed" zIndex="1">
          <Thead position="sticky" top="0">
            <Tr background="karneBlue.500">
              <Th textColor="white">Nummer ({values && values.docs.length})</Th>
              <Th textColor="white">Person 1</Th>
              <Th textColor="white">Sektion 1</Th>
              <Th textColor="white">Person 2</Th>
              <Th textColor="white">Sektion 2</Th>
              <Th textColor="white">Tid</Th>
            </Tr>
          </Thead>
          <Tbody>
            {values && values.docs.map((trade, index) => <TradeTableRow key={trade.data().timestamp} index={index} trade={trade.data() as Trade} />)}
          </Tbody>
        </Table>
      </Box>
      {allowDownloadEmailList && (
        <Modal size="xl" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Email lista</ModalHeader>
            <ModalCloseButton />
            <ModalBody pb="1rem">
              <Textarea minH="60vh" isReadOnly value={values && values.docs.map((user) => user.data().email).join(', \n')} />
            </ModalBody>
            <ModalFooter>
              <Button>Kopiera lista</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}
