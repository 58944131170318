import { sections as sectionsList, User } from '../constants/types';
import { db } from '../firebaseApp';
import { doc, deleteDoc, updateDoc, setDoc } from 'firebase/firestore';
import {
  useToast,
  Button,
  Tr,
  Text,
  Td,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Tooltip,
  Box,
  VStack,
} from '@chakra-ui/react';
import UserTradeItem from './UserTradeItem';
import { useState } from 'react';
import { useSections } from '../providers/SectionsProvider';
import { useSettings } from '../providers/SettingsProvider';
import SectionItem from './SectionItem';

interface Props {
  user: User;
  tradeAbles?: User[];
  id: string;
  action: string;
  index: number;
  showSection: boolean;
}

export default function UserTableRow({ id, user, action, tradeAbles, index, showSection }: Props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { selected: selectedSection, sections } = useSections();
  const { allowTrading } = useSettings();
  const toast = useToast();

  const [selected, setSelected] = useState('');
  const [sectionSelected, setSectionSelected] = useState('');

  let button;
  if (action === 'sell') {
    button = (
      <>
        <Td>
          <Tooltip label="Tryck här för att placera karnevalisten under potentiella byten">
            <Button onClick={onTrade}>Till potentiella</Button>
          </Tooltip>
        </Td>
        {allowTrading && (
          <Td>
            <Tooltip label="Tryck här för att göra karnevalisten tillgänglig för byte">
              <Button onClick={onSell}>Gör tillgänglig</Button>
            </Tooltip>
          </Td>
        )}
        {sections.includes('Admin') && (
          <Td>
            <Tooltip label="Tryck här för att flytta karnevalisten till en annan sektion">
              <Button colorScheme="karneRed" onClick={onOpen}>
                Flytta
              </Button>
            </Tooltip>
          </Td>
        )}
      </>
    );
  } else if (action === 'new') {
    button = (
      <>
        <Td>
          <Tooltip label="Tryck här för att ta in karnevalisten i din sektion ">
            <Button onClick={onAccept}>Acceptera</Button>
          </Tooltip>
        </Td>
        <Td>
          <Tooltip label="Tryck här för att inte godkänna karnevalisten till din sektion">
            <Button colorScheme="karneRed" onClick={onDeny}>
              Neka
            </Button>
          </Tooltip>
        </Td>
      </>
    );
  } else if (action === 'place') {
    button = (
      <Td>
        <Tooltip label="Ge karnevalisten en sektion">
          <Button onClick={onOpen}>Ge sektion</Button>
        </Tooltip>
      </Td>
    );
  } else if (action === 'buy') {
    button = (
      <>
        <Td>
          {selectedSection === user.section && (
            <Tooltip label="Tryck här för att ta bort karnevalisten från potentiella byten">
              <Button onClick={onRemove} colorScheme="karneRed">
                Ta bort
              </Button>
            </Tooltip>
          )}
        </Td>
        {allowTrading && <Td>{selectedSection !== user.section && <Button onClick={onOpen}>Ge Erbjudande</Button>}</Td>}
      </>
    );
  } else if (action === 'trade') {
    button = (
      <>
        <Td>
          <Tooltip label="Tryck här för att ta bort karnevalisten från potentiella byten">
            <Button onClick={onRemove} colorScheme="karneRed">
              Ta bort
            </Button>
          </Tooltip>
        </Td>
        {allowTrading && (
          <Td>
            <Tooltip label="Tryck här för att göra karnevalisten tillgänglig för byte">
              <Button onClick={onSell}>Gör tillgänglig</Button>
            </Tooltip>
          </Td>
        )}
      </>
    );
  }

  const dbUserRef = doc(db, 'users', id);
  const dbSellRef = doc(db, 'marketplace', id);
  const dbNewRef = doc(db, 'newUsers', id);
  const dbTradeRef = doc(db, 'trades', id);
  const dbOfferRef = doc(db, 'offers', id);

  function onSell() {
    setDoc(dbSellRef, user).then(() => {
      toast({
        title: user.firstName + ' ' + user.lastName,
        description: 'Är nu tillgänglig för byten!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    });
  }

  function onAccept() {
    updateDoc(dbUserRef, { section: user.section }).then(() => {
      deleteDoc(dbNewRef).then(() => {
        toast({
          title: user.firstName + ' ' + user.lastName,
          description: 'Är nu med i din sektion',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      });
    });
  }

  function onDeny() {
    updateDoc(dbUserRef, { section: user.oldSection }).then(() => {
      deleteDoc(dbNewRef).then(() => {
        toast({
          title: user.firstName + ' ' + user.lastName,
          description: 'Är nu bortagen från potentiella nya medlemar',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      });
    });
  }

  function onPlace() {
    user.oldSection = user.section;
    user.section = sectionSelected;

    updateDoc(dbUserRef, { section: 'pending' }).then(() => {
      setDoc(dbNewRef, user).then(() => {
        onClose();
        toast({
          title: user.firstName + ' ' + user.lastName,
          description: 'Är nu fram lagd som förslag till ' + sectionSelected,
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      });
    });
  }

  function onTrade() {
    setDoc(dbTradeRef, user).then(() => {
      toast({
        title: user.firstName + ' ' + user.lastName,
        description: 'Är tillgänglig för byte!',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    });
  }

  function onOffer() {
    const offerUser = tradeAbles?.filter((user) => user.docId === selected)[0];
    setDoc(dbOfferRef, {
      user: user,
      section: user.section,
      offer: offerUser,
      offerSection: offerUser?.section,
    }).then(() => {
      onClose();
      toast({
        title: 'Erbjudanted skickat',
        status: 'success',
        duration: 4000,
        isClosable: true,
      });
    });
  }

  function onRemove() {
    if (action === 'trade') {
      deleteDoc(dbTradeRef).then(() => {
        toast({
          title: user.firstName + ' ' + user.lastName,
          description: 'Är bortagen från potentiella byten!',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      });
    } else if (action === 'buy') {
      deleteDoc(dbSellRef).then(() => {
        toast({
          title: user.firstName + ' ' + user.lastName,
          description: 'Är bortagen från tillgänglig för byten!',
          status: 'success',
          duration: 4000,
          isClosable: true,
        });
      });
    }
  }

  return (
    <>
      <Tr>
        <Td>{index + 1}</Td>
        <Td>{user.firstName + ' ' + user.lastName}</Td>
        <Td>{user.gender}</Td>
        <Td>{user.email}</Td>
        <Td>{user.phone}</Td>
        {showSection && <Td>{user.section}</Td>}
        <Td>{user.role === 'Ansvarig' ? 'Ja' : 'Nej'}</Td>
        <Td>{user.bff ? user.bff : 'Har ingen KK'}</Td>
        <Tooltip
          fontSize="lg"
          label={<ul style={{ marginLeft: '1rem' }}>{user.interests && user.interests.map((s, i) => <li key={i}>{s}</li>)}</ul>}
          aria-label="A tooltip"
        >
          <Td isTruncated maxW="4rem">
            {user.interests && user.interests.map((s) => s + ', ')}
          </Td>
        </Tooltip>
        <Tooltip
          fontSize="lg"
          label={
            <ol style={{ marginLeft: '1.5rem' }}>
              {user?.sectionOrder ? user.sectionOrder.sort((s1, s2) => s1.order - s2.order).map((s, i) => <li key={i}>{s.name}</li>) : <li>Inga val gjorda</li>}
            </ol>
          }
          aria-label="A tooltip"
        >
          <Td isTruncated maxW="4rem">
            {user?.sectionOrder ? user.sectionOrder.sort((s1, s2) => s1.order - s2.order).map((s) => s.name + ', ') : 'Inga val gjorda'}
          </Td>
        </Tooltip>
        <Tooltip fontSize="lg" label={user.other}>
          <Td isTruncated maxW="12rem">
            {user.other}
          </Td>
        </Tooltip>
        {button}
      </Tr>
      {(action === 'place' || action === 'sell') && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Välj en sektion för {user.firstName + ' ' + user.lastName}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing="1rem">
                {sectionsList.map((section) => (
                  <SectionItem key={section} active={sectionSelected === section} section={section} onSelect={() => setSectionSelected(section)} />
                ))}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button mx="1rem" variant="outline" onClick={onPlace}>
                Placera
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {action === 'buy' && (
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Välj ett byt för {user.firstName}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing="1rem">
                {tradeAbles && tradeAbles.length > 0 ? (
                  tradeAbles.map((user) => (
                    <UserTradeItem key={user.docId} active={selected === user.docId} onSelect={() => setSelected(user.docId)} user={user} />
                  ))
                ) : (
                  <Text>Du behöver lägga till några potentiella byten innan du kan skapa ett bytes erbjudanden</Text>
                )}
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button mx="1rem" variant="outline" onClick={onOffer}>
                Trade
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}
