import { ReactNode } from 'react';
import { Button, Container, Heading, HStack, IconButton, Select, Tooltip, useColorMode, useColorModeValue } from '@chakra-ui/react';
import { signOut } from 'firebase/auth';
import { auth } from './firebaseApp';
import { NavLink, useMatch } from 'react-router-dom';
import { MoonIcon, RepeatClockIcon, SettingsIcon, SunIcon } from '@chakra-ui/icons';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useSections } from './providers/SectionsProvider';
import { useSettings } from './providers/SettingsProvider';

interface Props {
  children: ReactNode;
}

export default function Layout({ children }: Props) {
  const [user, userLoading, userError] = useAuthState(auth);
  const { sections, selected, setSelected } = useSections();
  const { allowTrading } = useSettings();
  const match = useMatch('/sign-in');
  const { toggleColorMode } = useColorMode();
  const icon = useColorModeValue(<MoonIcon />, <SunIcon />);

  return (
    <>
      <HStack mx="1rem" justifyContent="space-between" justifyItems="center" my="1rem">
        <NavLink to="/">
          <Heading size="md">Karnevalistadmin</Heading>
        </NavLink>
        <HStack spacing="1rem" overflowX="scroll">
          {user && (
            <>
              <NavLink to="/">
                <Button colorScheme="karneGreen">Mina sektionsmedlemar</Button>
              </NavLink>
              <NavLink to="/new-users">
                <Button colorScheme="karneRed">Tilldelade sektionsmedlemar</Button>
              </NavLink>
              {allowTrading && (
                <>
                  <NavLink to="/trades">
                    <Tooltip label="Potentiella byten från min sektion">
                      <Button colorScheme="karneGreen">Potentiella byten</Button>
                    </Tooltip>
                  </NavLink>
                  <NavLink to="/marketplace">
                    <Tooltip label="Tillgängliga att byta med från andra sektioner">
                      <Button colorScheme="karneBlue">Tillgängliga för byte</Button>
                    </Tooltip>
                  </NavLink>
                  <NavLink to="/offers">
                    <Tooltip label="Erbjudanden från andra sektioner">
                      <Button colorScheme="karneBlue">Erbjudanden</Button>
                    </Tooltip>
                  </NavLink>
                </>
              )}

              <Select size="lg" defaultValue={sections[0]} value={selected} onChange={(event) => setSelected(event.target.value)}>
                {sections && sections.map((s) => <option key={s}>{s}</option>)}
              </Select>
              {sections && (sections.includes('INFO/EXP') || sections.includes('Admin')) && (
                <NavLink to="/available">
                  <Tooltip label="Tillgängliga karnevalister utan sektion">
                    <Button colorScheme="karneOrange">Tillgängliga</Button>
                  </Tooltip>
                </NavLink>
              )}
              {sections && sections.includes('Admin') && (
                <>
                  <NavLink to="/settings">
                    <IconButton colorScheme="karneRed" aria-label="Settings" icon={<SettingsIcon />} />
                  </NavLink>
                  <NavLink to="/trade-history">
                    <IconButton colorScheme="karneOrange" aria-label="History" icon={<RepeatClockIcon />} />
                  </NavLink>
                </>
              )}
            </>
          )}
          <IconButton colorScheme="karneGreen" aria-label="Toggle dark mode" icon={icon} onClick={toggleColorMode} />
          {!match && (
            <Button minW="fit-content" colorScheme="karneRed" onClick={() => signOut(auth)}>
              Logga ut
            </Button>
          )}
        </HStack>
      </HStack>
      {children}
    </>
  );
}
