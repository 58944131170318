import { doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore';
import { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from '../firebaseApp';

type settingsContextReturn = {
  allowTrading: boolean;
  allowDownloadEmailList: boolean;
  setAllowTrading: (value: boolean) => void;
  setAllowDownloadEmailList: (value: boolean) => void;
};

const defaultContext: settingsContextReturn = {
  allowTrading: false,
  allowDownloadEmailList: false,
  setAllowDownloadEmailList: () => {},
  setAllowTrading: () => {},
};

const SettingsContext = createContext(defaultContext);

export function SettingsProvider({ children }: PropsWithChildren<{}>) {
  const [user, userLoading, userError] = useAuthState(auth);
  const [allowTrading, setStateAllowTrading] = useState(false);
  const [allowDownloadEmailList, setStateAllowDownloadEmailList] = useState(false);

  useEffect(() => {
    if (user) {
      const unsub = onSnapshot(doc(db, 'configs', 'admin'), (doc) => {
        if (doc.exists()) {
          setStateAllowTrading(doc.data().allowTrading);
          setStateAllowDownloadEmailList(doc.data().allowDownloadEmailList);
        }
      });
      return () => unsub();
    }
  }, [user, userLoading, userError]);

  function setAllowTrading(value: boolean) {
    updateDoc(doc(db, 'configs', 'admin'), {
      allowTrading: value,
    }).then((data) => {
      setStateAllowTrading(value);
    });
  }

  function setAllowDownloadEmailList(value: boolean) {
    updateDoc(doc(db, 'configs', 'admin'), {
      allowDownloadEmailList: value,
    }).then((data) => {
      setStateAllowDownloadEmailList(value);
    });
  }

  return (
    <SettingsContext.Provider value={{ allowTrading, allowDownloadEmailList, setAllowTrading, setAllowDownloadEmailList }}>{children}</SettingsContext.Provider>
  );
}

export function useSettings() {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
}

export default SettingsProvider;
